// eslint-disable-next-line no-restricted-imports
import { Settings } from "luxon";
import { dateTimeZone } from "../schema/dateTimeZone.js";

Settings.throwOnInvalid = true;
Settings.defaultZone = dateTimeZone;

declare module "luxon" {
	interface TSSettings {
		throwOnInvalid: true;
	}
}

// eslint-disable-next-line no-restricted-imports
export * from "luxon";
